import Link from 'next/link'
import { List, ListItem, ListLink, Nav } from './style'

const getNavLinks = items => {
  const entries = []
  let i = 1

  while (items[`title_${i}`]) {
    entries.push({
      title: items[`title_${i}`],
      link: items[`link_${i}`]
    })
    i++
  }
  return entries
}

const NavLinks = ({
  items,
  closeMenu,
  isMenuOpen,
  dark,
  darkAbout,
  expand
}) => {
  let menuItems = getNavLinks(items)

  return (
    <Nav className={isMenuOpen ? 'active' : null} expand={expand}>
      <List>
        {menuItems.map(item => (
          <ListItem key={item.title}>
            <Link href={item.link} passHref>
              <ListLink
                onClick={closeMenu}
                dark={dark || darkAbout}
                expand={expand}
              >
                {item.title}
              </ListLink>
            </Link>
          </ListItem>
        ))}
      </List>
    </Nav>
  )
}

export default NavLinks
