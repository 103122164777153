import PropTypes from 'prop-types'

const Whatsapp = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.5911 3.40887C18.3928 1.21051 15.4706 0 12.3615 0C12.3613 0 12.3605 0 12.3604 0C10.8226 0.000183105 9.3241 0.297913 7.90668 0.885132C6.48908 1.47235 5.21906 2.32141 4.13159 3.40887C1.93341 5.60706 0.722903 8.52979 0.722903 11.6385C0.722903 13.4925 1.17023 15.3349 2.01782 16.9764L0.0615262 22.5652C-0.0763522 22.9594 0.021243 23.3879 0.316775 23.6832C0.523501 23.8901 0.795596 24 1.07538 24C1.19532 24 1.31653 23.9799 1.43482 23.9385L7.02356 21.9824C8.6651 22.83 10.5075 23.2773 12.3615 23.2773C15.4702 23.2773 18.3928 22.0666 20.5911 19.8684C22.7893 17.6702 24 14.7475 24 11.6387C24 8.52979 22.7895 5.60706 20.5911 3.40887V3.40887ZM19.5958 18.873C17.6635 20.8055 15.0941 21.8696 12.3615 21.8696C10.6897 21.8696 9.02893 21.4561 7.5586 20.6735C7.29199 20.5318 6.97687 20.5074 6.69397 20.6065L1.61646 22.3835L3.39368 17.306C3.49274 17.0228 3.4682 16.7076 3.32648 16.4412C2.54407 14.9713 2.13043 13.3105 2.13043 11.6385C2.13043 8.90588 3.19464 6.33655 5.12696 4.40424C7.05908 2.47211 9.62805 1.4079 12.3605 1.40753H12.3615C15.0943 1.40753 17.6635 2.47174 19.5958 4.40424C21.5283 6.33655 22.5925 8.9057 22.5925 11.6385C22.5925 14.3714 21.5283 16.9407 19.5958 18.873V18.873Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9726 12.5199C16.4244 11.9717 15.5324 11.9717 14.9843 12.5199L14.3848 13.1194C12.8961 12.3082 11.6915 11.1035 10.8801 9.61474L11.4796 9.01519C12.028 8.467 12.028 7.57499 11.4796 7.02681L9.86405 5.41114C9.3159 4.86295 8.42394 4.86295 7.87579 5.41114L6.5834 6.7036C5.8431 7.44394 5.80617 8.71672 6.47936 10.2876C7.06387 11.6515 8.13355 13.1505 9.49137 14.5084C10.8492 15.8663 12.3482 16.9361 13.712 17.5206C14.4583 17.8405 15.1371 18 15.7237 18C16.3717 18 16.9071 17.8052 17.2958 17.4166L18.5881 16.1239V16.1241C18.8538 15.8585 19 15.5054 19 15.1299C19 14.7542 18.8538 14.4012 18.5881 14.1357L16.9726 12.5199ZM16.2767 16.3974C16.0654 16.6087 15.4157 16.6828 14.2798 16.1961C13.0804 15.682 11.7417 14.7207 10.5104 13.4893C9.27916 12.258 8.31802 10.9194 7.80399 9.71991C7.31714 8.58399 7.39119 7.93401 7.60246 7.72272L8.87011 6.45501L10.4358 8.021L9.68539 8.77165C9.34608 9.11099 9.26416 9.62561 9.48181 10.0523C10.4684 11.9871 12.0126 13.5313 13.9472 14.518C14.3741 14.7357 14.8887 14.6539 15.228 14.3144L15.9784 13.5639L17.5443 15.1299L16.2767 16.3974Z"
      fill={color}
    />
  </svg>
)

Whatsapp.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
}

Whatsapp.defaultProps = {
  width: '24',
  height: '24',
  color: '#fff'
}

export default Whatsapp
