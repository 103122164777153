import React, { useCallback } from 'react'

const useThrottle = (callback, time) => {
  const [onCooldown, setOnCooldown] = React.useState(false)

  const throttle = useCallback(
    (callback, time) => {
      if (!onCooldown) {
        callback()

        setTimeout(() => setOnCooldown(false), time)

        setOnCooldown(true)
      }
    },
    [onCooldown]
  )

  return () => throttle(callback, time)
}

export default useThrottle
