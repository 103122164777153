import { useState, useCallback, useEffect, useRef } from 'react'

// Library
import Instagram from 'library/Icons/Instagram'
import Whatsapp from 'library/Icons/Whatsapp'
import WhatsappColored from 'library/Icons/WhatsappColored'

// Styles
import {
  Wrapper,
  FlexContainer,
  Figure,
  Info,
  Text,
  DummyDiv,
  DummyContainer,
  FixedContainer
} from './style.js'

function useOnScreen(options) {
  const ref = useRef()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    }, observer)

    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current)
      }
    }
  }, [ref, options])

  return [ref, isVisible]
}

const Footer = ({ footer }) => {
  const { instagram, whatsapp, email, phone, text } = footer?.data
  const phoneLink = phone.replace(/\D/g, '')

  const [ref, isVisible] = useOnScreen({ threshold: 0 })

  return (
    <Wrapper ref={ref}>
      <FlexContainer>
        <FixedContainer>
          <Figure>
            <a
              href={`https://wa.me/${whatsapp}`}
              title="Whatsapp"
              target="_blank"
              rel="noopener"
            >
              <WhatsappColored
                width={isVisible ? '0' : undefined}
                height={isVisible ? '0' : undefined}
              />
            </a>
          </Figure>
        </FixedContainer>

        <DummyContainer>
          <Figure>
            <a
              href={instagram}
              title="Instagram"
              target="_blank"
              rel="noopener"
            >
              <Instagram />
            </a>
          </Figure>
          <Figure>
            <a
              href={`https://wa.me/${whatsapp}`}
              title="Whatsapp"
              target="_blank"
              rel="noopener"
            >
              <Whatsapp />
            </a>
          </Figure>
        </DummyContainer>

        <Info>
          <Text>
            <a
              href={`mailto:${email}`}
              title={email}
              target="_blank"
              rel="noopener"
            >
              {email}
            </a>
          </Text>
          <Text>
            <a
              href={`tel:+55${phoneLink}`}
              title={phone}
              target="_blank"
              rel="noopener"
            >
              {phone}
            </a>
          </Text>
          <Text>{text}</Text>
        </Info>

        <DummyContainer>
          <DummyDiv />
          <DummyDiv />
        </DummyContainer>
      </FlexContainer>
    </Wrapper>
  )
}

export default Footer
