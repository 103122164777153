import PropTypes from 'prop-types'

const Instagram = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.253 24h13.494A5.259 5.259 0 0 0 24 18.747V5.253A5.259 5.259 0 0 0 18.747 0H5.253A5.259 5.259 0 0 0 0 5.253v13.494A5.259 5.259 0 0 0 5.253 24zM1.406 5.253a3.85 3.85 0 0 1 3.847-3.847h13.494a3.85 3.85 0 0 1 3.847 3.847v13.494a3.85 3.85 0 0 1-3.847 3.847H5.253a3.85 3.85 0 0 1-3.847-3.847V5.253z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 12c0 3.86 3.14 7 7 7s7-3.14 7-7-3.14-7-7-7-7 3.14-7 7zm1.36 0c0-3.11 2.53-5.64 5.64-5.64s5.64 2.53 5.64 5.64-2.53 5.64-5.64 5.64S6.36 15.11 6.36 12z"
      fill={color}
    />
    <path
      d="M19.998 5a.993.993 0 0 1-.964-.74.994.994 0 0 1 .1-.76.993.993 0 0 1 .607-.465.992.992 0 0 1 .759.1c.23.133.396.349.465.607A1 1 0 0 1 19.998 5z"
      fill={color}
    />
  </svg>
)

Instagram.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
}

Instagram.defaultProps = {
  width: '24',
  height: '24',
  color: '#fff'
}

export default Instagram
