import styled from '@emotion/styled'

const Wrapper = styled.div`
  padding: 0 1rem;
  width: 100%;
  max-width: 1248px;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1.777rem;
  }
`

const Inner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    width: fit-content;
  }
`

const Outter = styled.header`
  position: fixed;
  top: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 2.666rem;

  background-color: ${({ theme }) => theme.colors.primaryDarkest};
  transition: background-color 0.4s ease;

  ${Wrapper} {
    height: 100%;
  }

  &.active {
    transition: background-color 0s ease;
    align-items: start;

    @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
      background: transparent;

      height: 8.3333rem;
      margin-top: 2.666rem;

      ${Wrapper} {
        height: auto;
      }
    }
  }
`

export { Outter, Wrapper, Inner }
