import Link from 'next/link'
import PropTypes from 'prop-types'

import { Wrapper, ButtonContainer, Bar } from './style.js'
import Logo from 'library/Icons/Logo/index.jsx'
import { Mobile } from 'library/Dimension/index.jsx'
import { Desktop } from 'library/Dimension/index.jsx'

const NavHeader = ({ handleButton, isMenuOpen, isFull, dark, darkAbout }) => (
  <Wrapper isFull={isFull} className={!isFull && !isMenuOpen ? 'expand' : null}>
    <Link href="/" passHref>
      <a title="JS Studio">
        {!isMenuOpen && (
          <>
            <Mobile>
              <Logo dark={dark} full={!isFull} small />
            </Mobile>
            <Desktop>
              <Logo dark={dark} full={!isFull} small={isFull} />
            </Desktop>
          </>
        )}
      </a>
    </Link>
    <ButtonContainer onClick={handleButton}>
      <Bar className={isMenuOpen ? 'active' : null} dark={dark || darkAbout} />
    </ButtonContainer>
  </Wrapper>
)

NavHeader.propTypes = {
  isMenuOpen: PropTypes.bool,
  isFull: PropTypes.bool,
  dark: PropTypes.bool
}

NavHeader.defaultProps = {
  isMenuOpen: false,
  isFull: true,
  dark: false
}

export default NavHeader
