import styled from '@emotion/styled'

// Library
import Container from 'library/Container'

const Wrapper = styled.footer`
  padding: 1.5rem 0;

  background-color: ${({ theme }) => theme.colors.primaryDarkest};

  font-family: 'Mada', sans-serif;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 1.1111rem 0;
  }
`

const FlexContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    justify-content: space-between;
    flex-direction: row-reverse;

    padding: 0 1.777rem;
  }
`

const Figure = styled.figure`
  display: flex;

  > a {
    display: flex;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-top: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    align-self: center;
    flex-direction: row;

    margin: 0;
  }
`

const Text = styled.p`
  margin: 0.5rem 0;

  text-align: center;
  line-height: 22px;
  letter-spacing: 0.73px;

  color: #fff;

  > a {
    padding: 0.5rem;
    color: #fff;
    cursor: pointer;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    margin: 0;
    padding: 0 1.25rem;

    border-left: 1px solid #fff;

    > a {
      padding: 0;
    }

    &:first-of-type {
      border-left: none;
    }
  }
`

const DummyDiv = styled.div`
  display: none;

  width: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: block;
  }
`

const DummyContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 4.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    max-width: 3.8888rem;
  }
`

const FixedContainer = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  display: block;
  z-index: 20;

  max-width: 3rem;
  max-height: 3rem;
`

export {
  Wrapper,
  FlexContainer,
  Figure,
  Info,
  Text,
  DummyDiv,
  DummyContainer,
  FixedContainer
}
