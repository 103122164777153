import { useState, useCallback, useEffect, useContext } from 'react'

import NavHeader from 'components/NavHeader'
import NavLinks from 'components/NavLinks'

import useThrottle from 'library/Hook/throttle'

import { Outter, Wrapper, Inner } from './style'

const Header = ({ header, darkHeader, darkAbout }) => {
  const { data } = header

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isFull, setIsFull] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)

  const handleVisibility = useCallback(() => {
    const currentScrollPos = window.pageYOffset
    const threshold = currentScrollPos < 20 ? false : true
    setIsFull(isMenuOpen ? true : threshold)
  }, [isMenuOpen])

  const handleWidth = useCallback(() => setWindowWidth(window.innerWidth), [])

  const handleScroll = useThrottle(handleVisibility, 50)
  const handleResize = useThrottle(handleWidth, 600)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [handleResize])

  useEffect(() => {
    const doc = document.documentElement

    if (isMenuOpen && windowWidth < 600) {
      doc.style.overflow = 'hidden'
      doc.style.maxHeight = '100vh'
    } else {
      doc.style.overflow = 'visible'
      doc.style.maxHeight = 'none'
    }
  }, [isMenuOpen, windowWidth])

  useEffect(() => {
    if (windowWidth > 600) {
      setIsMenuOpen(false)
    }
  }, [windowWidth])

  const handleButton = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  return (
    <Outter className={!isFull && !isMenuOpen ? 'active' : null}>
      <Wrapper>
        <Inner>
          <NavHeader
            dark={darkHeader}
            darkAbout={darkAbout}
            handleButton={handleButton}
            isMenuOpen={isMenuOpen}
            isFull={isFull}
          />
        </Inner>
        <NavLinks
          items={data}
          closeMenu={closeMenu}
          isMenuOpen={isMenuOpen}
          dark={darkHeader}
          darkAbout={darkAbout}
          expand={!isFull && !isMenuOpen}
        />
      </Wrapper>
    </Outter>
  )
}

export default Header
