import PropTypes from 'prop-types'

const WhatsappColored = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.17 7.76025C41.04 2.80125 34.29 0 27.1013 0C6.46875 0 -6.48675 22.3537 3.816 40.1355L0 54L14.2538 50.283C20.4638 53.6377 25.551 53.3408 27.1147 53.5388C51.039 53.5388 62.9482 24.5947 46.1362 7.848L46.17 7.76025Z"
      fill="#ECEFF1"
    />
    <path
      d="M27.1507 48.9396L27.1372 48.9374H27.1012C19.9417 48.9374 15.3675 45.5466 14.9175 45.3509L6.47998 47.5446L8.74123 39.3434L8.20348 38.4996C5.97598 34.9536 4.79248 30.8699 4.79248 26.6646C4.79248 6.8804 28.9687 -3.01285 42.9592 10.9709C56.916 24.8084 47.1172 48.9396 27.1507 48.9396Z"
      fill="#4CAF50"
    />
    <path
      d="M39.3907 32.1908L39.3704 32.3596C38.6932 32.0221 35.3947 30.4088 34.7804 30.1861C33.4012 29.6753 33.7904 30.1051 31.1422 33.1381C30.7484 33.5768 30.3569 33.6106 29.6887 33.3068C29.0137 32.9693 26.8469 32.2606 24.2819 29.9656C22.2839 28.1768 20.9429 25.9831 20.5469 25.3081C19.8877 24.1696 21.2669 24.0076 22.5224 21.6316C22.7474 21.1591 22.6327 20.7878 22.4662 20.4526C22.2974 20.1151 20.9542 16.8076 20.3917 15.4891C19.8517 14.1751 19.2959 14.3416 18.8797 14.3416C17.5837 14.2291 16.6364 14.2471 15.8017 15.1156C12.1702 19.1071 13.0859 23.2246 16.1932 27.6031C22.2997 35.5951 25.5532 37.0666 31.5022 39.1096C33.1087 39.6203 34.5734 39.5483 35.7322 39.3818C37.0237 39.1771 39.7079 37.7596 40.2682 36.1733C40.8419 34.5871 40.8419 33.2708 40.6732 32.9671C40.5067 32.6633 40.0657 32.4946 39.3907 32.1908V32.1908Z"
      fill="#FAFAFA"
    />
  </svg>
)

WhatsappColored.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
}

WhatsappColored.defaultProps = {
  width: '54',
  height: '54',
  color: '#fff'
}

export default WhatsappColored
