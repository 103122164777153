import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

const ListAnimation = keyframes`
  0% {
    transform: scale(0, 0);
    transform-origin: 100% 0%;
  }
  40% {
    transform: scale(.6, .3);
    transform-origin: 100% 0%;
  }
  60% {
    transform: scale(1, .6);
    transform-origin: 100% 0%;
  }
  100% {
    transform: scale(1, 1);
    transform-origin: 100% 0%;
  }
`

const ListItem = styled.li`
  width: 100%;
  padding: 1rem;

  text-align: center;
  font-size: 48px;
  font-weight: 300;

  transition: all 0.2s ease;

  &:hover {
    font-weight: 800;
    font-style: italic;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: 0;

    text-transform: uppercase;
    font: ${({ theme }) => theme.typography.paragraphDesk};
    font-weight: 700;

    &:hover {
      font-style: normal;
    }
  }
`
const ListLink = styled.a`
  display: block;

  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);

  color: ${({ dark }) => (dark ? '#6c6e70' : '#FFF')};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    clip-path: none;

    font-size: ${({ expand }) => !expand && '0.777rem'};
    color: ${({ dark, expand }) =>
      dark ? (expand ? '#6c6e70' : '#FFF') : '#FFF'};
  }
`

const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  margin: auto;

  background-color: ${({ theme }) => theme.colors.primaryDarkest};

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    justify-content: space-evenly;
    flex-direction: row;

    background-color: transparent;
  }
`

const Nav = styled.nav`
  position: absolute;
  top: 2rem;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow: auto;

  width: 100%;
  height: 100vh;
  padding: 1rem 0 4rem;

  background-color: ${({ theme }) => theme.colors.primaryDarkest};

  transform: translateY(-150%);

  &.active {
    transform: translateY(0%);
    animation: ${ListAnimation} 0.4s ease-in-out forwards;

    ${ListLink} {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);

      transition: clip-path 0.5s ease 0.5s;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: flex;
    position: initial;

    max-width: ${({ expand }) => (expand ? '32rem' : '24rem')};
    height: 100%;
    padding: 0;
    margin-bottom: ${({ expand }) => (expand ? 'auto' : 'none')};

    transform: none;
    background-color: transparent;
  }
`

export { List, ListItem, ListLink, Nav }
