import styled from '@emotion/styled'
import { css } from '@emotion/react'

const Mobile = styled.div(({ theme, breakpoint }) =>
  breakpoint
    ? css`
        width: 100%;

        @media (min-width: ${breakpoint}px) {
          display: none;
        }
      `
    : css`
        width: 100%;

        @media (min-width: ${theme.breakpoints.phone}px) {
          display: none;
        }
      `
)

const Desktop = styled.div(({ theme, breakpoint }) =>
  breakpoint
    ? css`
        display: none;

        width: 100%;

        @media (min-width: ${breakpoint}px) {
          display: block;
        }
      `
    : css`
        display: none;

        width: 100%;

        @media (min-width: ${theme.breakpoints.phone}px) {
          display: block;
        }
      `
)

export { Desktop, Mobile }
