import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'

//Library
import { Desktop } from 'library/Dimension/index.jsx'
import { Mobile } from 'library/Dimension/index.jsx'

const LogoAnimation = keyframes`
  0% {
    opacity: 1;
  }
  90% {
    opacity: 0;
  }
  100% {
    width: 0;
    height: 0;
  }
`

const Logo = styled.img`
  display: block;

  opacity: 1;

  &.active {
    animation: ${LogoAnimation} 0.4s linear forwards;
  }
`

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-left: auto;

  width: 2.0625rem;
  padding: 0.8125rem 0;

  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    display: none;
  }
`
const Bar = styled.span`
  display: block;

  width: 90%;
  height: 2.11px;

  border-radius: 2px;

  background-color: ${({ dark }) => (dark ? '#6c6e70' : '#FFF')};

  transition: all 0.15s ease-in;

  &::after,
  ::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 90%;
    height: 2.11px;

    border-radius: 2px;
    background-color: ${({ dark }) => (dark ? '#6c6e70' : '#FFF')};

    transition: all 0.15s ease-in;
  }

  &::before {
    bottom: calc(100% - 4px);
  }

  &.active {
    background-color: transparent;

    &::after {
      transform: rotate(45deg);
      bottom: calc(50% - 2px);
    }

    &::before {
      transform: rotate(-45deg);
      bottom: calc(50% - 2px);
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 3.5rem;

  z-index: 25;

  ${Mobile} {
    display: flex;
    max-width: 2rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    height: 100%;
    align-items: center;

    ${Desktop} {
      display: flex;
      max-width: 2rem;
    }

    ${Mobile} {
      display: none;
    }

    &.expand {
      height: 100%;
      align-items: start;

      ${ButtonContainer} {
        margin-bottom: auto;
      }

      ${Logo} {
        width: 12vw;
        max-width: 7.5rem;
      }

      ${Desktop} {
        max-width: unset;
      }
    }
  }
`

export { Wrapper, Logo, ButtonContainer, Bar }
