import styled from '@emotion/styled'
import PropTypes from 'prop-types'

// Hooks
// import { useLoader } from '../../hooks'

// Components
// import Header from 'components/Header'
import Menu from 'components/Menu'
import Footer from 'components/Footer'
import Header from 'components/Header'
import MetaTags from 'components/MetaTags'

// Constants
import {
  SITE_TITLE,
  SITE_DESCRIPTION,
  SITE_URL,
  SITE_KEYWORDS
} from '../../constants/tags'
import { useState, useEffect } from 'react'

// Styles
const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: ${({ dark, theme }) =>
    dark ? theme.colors.primaryDarkestAlt : '#FFF'};

  min-height: calc(100vh - 207px);
  margin-top: 2.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    min-height: calc(100vh - 85px);
    margin-top: 0;
  }
`

const Layout = ({
  children,
  metaTags,
  footer,
  header,
  dark,
  darkHeader,
  darkAbout
}) => {
  // const { loading } = useLoader()
  const [url, setUrl] = useState(null)

  useEffect(() => {
    if (window) setUrl(window.location.href)
  }, [])

  return (
    <>
      <Header
        header={header.data}
        darkHeader={darkHeader}
        darkAbout={darkAbout}
      />
      <Main dark={dark}>
        {url && (
          <MetaTags
            title={metaTags.title}
            description={metaTags.description}
            keywords={metaTags.keywords}
            twitter={metaTags.twitter}
            images={metaTags.images}
            url={url}
            nextSeo={metaTags.nextSeo}
          />
        )}
        {children}
      </Main>
      {footer?.data && <Footer footer={footer.data} />}
    </>
  )
}

Layout.propTypes = {
  metaTags: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    keywords: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.object),
    nextSeo: PropTypes.object
  })
}

Layout.defaultProps = {
  metaTags: {
    title: SITE_TITLE,
    description: SITE_DESCRIPTION,
    keywords: SITE_KEYWORDS,
    images: [],
    nextSeo: {}
  }
}

export default Layout
