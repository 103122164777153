import PropTypes from 'prop-types'
import styled from '@emotion/styled'

const Container = styled.div`
  width: 100%;
  max-width: ${props => (props.size ? props.size : '1248')}px;
  margin: 0 auto;
  padding: ${props => (props.hasPadding ? '0 1rem' : 0)};
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.phone}px) {
    padding: ${props => (props.hasPadding ? '0 1.777rem' : 0)};
  }
`

Container.defaultProps = {
  hasPadding: true
}

Container.propTypes = {
  hasPadding: PropTypes.bool,
  size: PropTypes.number
}

export default Container
